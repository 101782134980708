import ErrorIcon from '@/components/atoms/Icon/svg/ErrorIcon'
import InputField from '@/components/atoms/InputField'
import theme from '@/theme'
import { thousandSeparator } from '@/utils/numHelper'
import { TableCell, TableRow, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { TItemDataTableAntiWarming } from '../AntiWarmingTable'

interface TAntiWarmingTableRow {
    dataRow: TItemDataTableAntiWarming
    index: number
    setData: (data: TItemDataTableAntiWarming[]) => void
    data: TItemDataTableAntiWarming[] | []
    classNameRow?: string
}

const ErrorText = styled.div`
    color: ${theme.colors.error};
    margin-top: 2px;
    font-size: 12px;
    width: max-content;
    float: right;
`

const useStyle = makeStyles({
    tableCell: {
        padding: '8px 30px 8px 16px',
        color: theme.colors.black2,
        fontSize: 14,
    },
    firstCol: {
        fontSize: 14,
        fontWeight: 300,
        backgroundColor: '#FDF3DF',
        borderTop: `4px solid #fff`,
        borderRight: `4px solid #fff`,
        position: 'sticky',
        left: 1,
    },
    totalCol: {
        backgroundColor: '#F2F2F2',
        borderRight: `4px solid #fff`,
    },
    firsttotalCol: {
        borderTop: `4px solid #fff`,
    },
    other: {
        backgroundColor: '#F5DBAE',
        padding: '12px 16px 12px 16px',
    },
    inputCol: {
        padding: '8px 0px 8px 16px',
    },
    input: {
        width: 135,
        marginLeft: 7,
        '& fieldset': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
        },
        '& input': {
            borderColor: theme.colors.lightGray2,
            borderRadius: 7,
            textAlign: 'right',
        },
    },
})

function AntiWarmingTableRow(props: TAntiWarmingTableRow) {
    const { dataRow, index, setData, data } = props
    const [value, setValue] = useState(
        typeof dataRow?.['anti_warming_10'] === 'number' ? `${Math.round(dataRow?.['anti_warming_10'])}` : ''
    )
    const [error, setError] = useState<String>()
    const classes = useStyle()

    const antiWarmingItems = [
        { id: 1, name: '①エネルギー起源CO2（②を除く）', width: 120 },
        { id: 2, name: '②廃棄物の原燃料使用に伴うエネルギー起源CO2', width: 120 },
        { id: 11, name: '③非エネルギー起源CO2（④を除く）', width: 120 },
        { id: 3, name: '④廃棄物の原燃料使用に伴う非エネルギー起源CO2', width: 128 },
        { id: 4, name: '⑤メタン', width: 120 },
        { id: 5, name: '⑥N2O', width: 120 },
        { id: 6, name: '⑦HFC', width: 120 },
        { id: 7, name: '⑧PFC', width: 120 },
        { id: 8, name: '⑨SF6', width: 120 },
        { id: 9, name: '⑩NF3', width: 120 },
        { id: 10, name: '⑪エネルギー起源CO2(発電所等配分前)', width: 150 },
    ]

    const handleInputChange = (value: string) => {
        const ZENKAKU_NUMBERS = /[\uFF10-\uFF19]/

        if (ZENKAKU_NUMBERS.test(value)) {
            setError('半角数字を入力してください')
            return
        }

        const newValue = Number(value)
        if (isNaN(newValue)) {
            setError('半角数字を入力してください')
            return
        }

        const [integerPart, decimalPart] = value.split('.')
        if (integerPart?.length > 12) {
            setError('最大12桁まで')
            return
        }
        if (decimalPart?.length > 3) {
            setError('小数点以下は3桁まで')
            return
        }

        if (value.charAt(0) === '0' && value.charAt(1) === '0') {
            setValue('0')
            return
        }

        setError('')

        const tmpData = [...data]
        const index = tmpData.findIndex((item: TItemDataTableAntiWarming) => item.site_id === dataRow.site_id)
        if (index > -1 && value !== '') tmpData[index]['anti_warming_10'] = Number(value)
        else delete tmpData[index]['anti_warming_10']
        if (dataRow?.['anti_warming_10'] && value === '') tmpData[index]['anti_warming_10'] = 0
        setData(tmpData)
        setValue(thousandSeparator(value))
    }

    useEffect(() => {
        setError('')
        setValue(
            typeof dataRow?.['anti_warming_10'] === 'number' ? `${dataRow?.['anti_warming_10']}` : ''
        )
    }, [props.dataRow])

    const renderSpaces = (level?: number) => {
        if (!level) {
            return ''
        }
        const spaces = []
        let count = 1
        while (level > count) {
            spaces.push(String.fromCharCode(160), String.fromCharCode(160), String.fromCharCode(160))
            count++
        }
        return spaces.join('')
    }

    return (
        <TableRow key={dataRow.site_id} className={props?.classNameRow || ''}>
            <TableCell
                key={`${dataRow.site_id}-name`}
                className={`${classes.tableCell} ${classes.firstCol} ${dataRow.name === '合計' ? classes.other : ''}`}
            >
                {renderSpaces(dataRow.level)}{dataRow.name}
            </TableCell>
            <TableCell
                key={`${dataRow.site_id}-total-1`}
                className={`${classes.tableCell} ${classes.totalCol} ${index === 0 ? classes.firsttotalCol : ''}`}
                align="right"
            >
                {dataRow.name === '合計'
                    ? thousandSeparator(typeof dataRow?.[`total_1`] === 'number' ? `${Math.round(dataRow?.[`total_1`] as number)}` : '-')
                    : thousandSeparator(dataRow?.total && dataRow.level == 1 ? `${Math.round(dataRow?.total)}` : '-')
                }
            </TableCell>
            <TableCell
                key={`${dataRow.site_id}-total-2`}
                className={`${classes.tableCell} ${classes.totalCol} ${index === 0 ? classes.firsttotalCol : ''}`}
                align="right"
            >
                {dataRow.name === '合計'
                    ? thousandSeparator(typeof dataRow?.[`total_2`] === 'number' ? `${Math.round(dataRow?.[`total_2`] as number)}` : '-')
                    : thousandSeparator(dataRow?.total && dataRow.level == 2 ? `${Math.round(dataRow?.total)}` : '-')
                }
            </TableCell>
            <TableCell
                key={`${dataRow.site_id}-total-3`}
                className={`${classes.tableCell} ${classes.totalCol} ${index === 0 ? classes.firsttotalCol : ''}`}
                align="right"
            >
                {dataRow.name === '合計'
                    ? thousandSeparator(typeof dataRow?.[`total_3`] === 'number' ? `${Math.round(dataRow?.[`total_3`] as number)}` : '-')
                    : thousandSeparator(dataRow?.total && dataRow.level == 3 ? `${Math.round(dataRow?.total)}` : '-')
                }
            </TableCell>
            <TableCell
                key={`${dataRow.site_id}-total-4`}
                className={`${classes.tableCell} ${classes.totalCol} ${index === 0 ? classes.firsttotalCol : ''}`}
                align="right"
            >
                {dataRow.name === '合計'
                    ? thousandSeparator(typeof dataRow?.[`total_4`] === 'number' ? `${Math.round(dataRow?.[`total_4`] as number)}` : '-')
                    : thousandSeparator(dataRow?.total && dataRow.level == 4 ? `${Math.round(dataRow?.total)}` : '-')
                }
            </TableCell>
            <TableCell
                key={`${dataRow.site_id}-total-5`}
                className={`${classes.tableCell} ${classes.totalCol} ${index === 0 ? classes.firsttotalCol : ''}`}
                align="right"
            >
                {dataRow.name === '合計'
                    ? thousandSeparator(typeof dataRow?.[`total_5`] === 'number' ? `${Math.round(dataRow?.[`total_5`] as number)}` : '-')
                    : thousandSeparator(dataRow?.total && dataRow.level == 5 ? `${Math.round(dataRow?.total)}` : '-')
                }
            </TableCell>
            {Array.from({ length: 10 }, (_, index: number) => (
                <TableCell
                    key={`${dataRow.site_id}-item-${index}`}
                    className={classes.tableCell}
                    align="right"
                >
                    {thousandSeparator(typeof dataRow?.[`anti_warming_${antiWarmingItems[index].id}`] === 'number' ? `${Math.round(dataRow?.[`anti_warming_${antiWarmingItems[index].id}`] as number)}` : '-')}
                </TableCell>
            ))}
            {dataRow.name === '合計' ? (
                <TableCell
                    key={`${dataRow.site_id}-item-last`}
                    className={classes.tableCell}
                    align={
                        thousandSeparator(typeof dataRow?.[`anti_warming_10`] === 'number' ? `${Math.round(dataRow?.[`anti_warming_10`])}` : '-') !== '-'
                            ? 'right'
                            : 'center'
                    }
                    style={{ paddingRight: 1 }}
                >
                    {thousandSeparator(typeof dataRow?.[`anti_warming_10`] === 'number' ? `${Math.round(dataRow?.[`anti_warming_10`])}` : '-')}
                </TableCell>
            ) : (
                <TableCell
                    key={`${dataRow.site_id}-item-last`}
                    className={classes.inputCol}
                    align="right"
                >
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                        <span style={{ width: 14, marginTop: 5 }}>{error && <ErrorIcon />}</span>
                        <InputField
                            value={thousandSeparator(value)}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                return handleInputChange(e.target.value.split(',').join(''))
                            }}
                            error={!!error}
                            className={classes.input}
                        />
                    </div>
                    {error && <ErrorText>{error}</ErrorText>}
                </TableCell>
            )}
        </TableRow>
    )
}
export default React.memo(AntiWarmingTableRow)
