import theme from '@/theme'
import { makeStyles } from '@material-ui/core'
import React from 'react'

const useStyle = makeStyles({
    textNote: {
        marginTop: 15,
        textAlign: 'left',
        color: theme.colors.black2,
    },
})

export default function NoteFooterTable(props: { fontSize?: number }) {
    const classes = useStyle()
    return (
        <div className={classes.textNote} style={{ fontSize: props.fontSize || 14 }}>
            ※各カテゴリ、または各算定項目の数値は端数処理をしているため、小計と合計が一致しない場合があります。
        </div>
    )
}
